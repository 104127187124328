<template>
  <!-- ============ Body content start ============= -->
  <div class="main-content">
    <h2>Dashboard</h2>
<!--    <breadcumb :page="'Version 1'" :folder="'Dashboard'" />-->
    <b-row>

    </b-row>

  </div>
  <!-- ============ Body content End ============= -->
</template>
<script>

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Dashboard"
  },
  data() {
    return {

    };
  }
};
</script>
<style>
</style>
